@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap');

.App {
  text-align: center;
  background-color: white;
  width: 100vw;
  text-decoration: none;
  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif;
  color: #444;
  font-size: 16px;

  &-content {
    margin: 100px auto;
    min-height: calc(100vh - 100px - 160px);
  }
}

.Page {
  &-photo {
      width: 40vw;
      display: block;
      margin: 10px;
      max-width: 600px;
  }

  &-title {
    font-size: 16px;
    padding: 20px 0 50px;
    font-weight: 700;
    color: transparent;
  }

  &-content {
    width: 100%;
    padding: 20px;
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin-bottom: 5px;
      line-height: 24px;
    }
  }
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 90vw;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  max-width: 1000px;

  &::after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  .horizontal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.centered-highlight-text {
  font-size: 20px;
  line-height: 40px;
  margin: 80px auto;
  max-width: 75%;
}

.Bold {
  font-weight: bold;
}

@media only screen and (max-width: 1000px) {
  .App-content {
    margin: 70px auto;
    min-height: calc(100vh - 70px - 70px - 60px);
  }

  .Page {
    &-content {
      padding: 20px 0;
    }

    &-title {
      color: #444;
    }

    &-photo {
      width: 90vw;
    }
  }

  .centered-highlight-text {
    font-size: 15px;
    line-height: 25px;
    margin: 40px auto;
    width: 75%;
  }
}