.Header-desktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #444;
  background-color: white;
  text-decoration: none;
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  z-index: 15;

  .Header-home {
    margin: 0 30px;
    width: 300px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .Menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
  }

  .Menu > li {
    margin-left: 20px;
  }

  .Menu a:hover {
    color: #333;
  }

  .Menu a.active {
    font-weight: bold;
  }

  .Sub-menu {
      display: none;
      position: absolute;
      background-color: white;
      z-index: 1000;
      margin-left: -20px;
      border-radius: 4px;
  }

  .Sub-menu-parent {
    display: block;
  }

  .Sub-menu-parent:hover .Sub-menu {
      display: block;
  }
  .Sub-menu:hover {
      display: block;
  }
  .Sub-menu > li {
      padding: 10px 20px;
  }

  .Sub-menu:first-child {
      padding-top: 15px;
  }

  .language-picker {
    position: absolute;
    right: 10px;
    cursor: pointer;

    & *:hover {
      color: grey;
    }
  }
}

/* MOBILE */
.Header-mobile {
  display: none;
  position: fixed;
  background-color: #fff;
  top: 0;
  height: 70px;
  z-index: 100;

  .Header-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    margin: 10px 0;
    z-index: 10;

    > * {
      height: 100%;
      margin: 0 20px;
      display: flex;
    }
  }

  .Header-home {
    height: 140%;
  }

  .Modal {
    width: 100%;
    height: 0;
    position: fixed;
    z-index: 1000;
    display: flex;
    justify-content: center;
    background-color: white;
    overflow: hidden;
    opacity: 0;
    transition: all .2s linear;

    &-open {
      opacity: 1;
      height: 100%;
    }
  }

  .Menu-icon {
    width: 16px;
    height: 100%;
  }


  .Close {
    position: absolute;
    top: 30px;
    right: 20px;
    > * {
      width: 16px;
    }
  }

  .Menu-items {
    margin-top: 30vw;

    >li {
      margin-bottom: 10px;

      & .active {
        font-weight: bold;
      }
    }
  }

  .language-picker {
    position: absolute;
    bottom: 16px;
    cursor: pointer;
    color: #444;
  }
}

.Header-logo {
  text-transform: uppercase;
  font-family: 'Arimo', sans-serif;
  font-size: 30px;
  margin: auto;
}

@media only screen and (max-width: 1000px) {
  .Header-desktop {
    display: none;
  }

  .Header-mobile {
    display: flex;
  }
}

// @keyframes showFalcao {
//     0% {
//         opacity: 0;
//         transform: translateY(20px);
//     }
//     100% {
//         opacity: 1;
//         transform: translateY(0);
//     }
// }