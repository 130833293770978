.Footer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 25px;
    height: 60px;
    font-size: 12px;

    .Socials {
        &-link {
            width: 16px;
            margin: 5px;

            & img {
                width: inherit;
            }
        }
    }
}