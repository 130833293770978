.PageTitle {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 20px;

    &-label {
        position: absolute;
        z-index: 10;
        color: white;
        font-size: 40px;
        font-weight: 900;
        letter-spacing: 1px;
    }

    &-image {
        width: 100%;
        opacity: 0.6;
        object-fit: cover;
        transition: opacity .25s ease-in-out;
    }
}

@media only screen and (max-width: 1000px) {
    .PageTitle {
        height: 200px;
    }
  }