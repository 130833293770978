.About {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &-content {
        display: flex;
        align-items: center;
        width: 90vw;
        flex-direction: column;
        justify-content: space-evenly;
    }

    &-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .Contacts-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
    }

    .Contacts-button {
        padding: 5px 25px;
        margin: 50px 0;
        border: 1px solid black;
        border-radius: 1px;
        display: inline-block;

        :hover {
            background-color: #ccc;
        }
    }

    .Page-content {
        padding-top: 10px;
        align-items: flex-start;
    }

    .Brands {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-grid {
            display: flex;
            margin: auto;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }
    .Brand {
        padding: 5px 15px;
        width: 70px;

        & img {
            width: inherit;
            margin: auto;
        }
    }
}

@media only screen and (min-width: 1000px) {
    .About .Brands-grid {
        width: 100%;
    }

    .About{
        &-content {
            flex-direction: row;
            align-items: flex-start;
        }
        .Page-content {
            width: 40vw;
        }
    }
  }