.Video {
    &-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .Video-container {
        max-width: 45vw;
        min-height: 75vh;
        padding: 10px;
    }

    .Video-container.horizontal {
        width: 45vw;
    }
}

@media only screen and (max-width: 1000px) {
    .Video .Video-container {
        max-width: 80vw;
        padding: 0;

        &.horizontal {
            width: 80vw;
        }
    }
}