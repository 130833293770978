.MenuLinks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .Link {
        width: 50vw;
        max-height: 50vh;
        overflow: hidden;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        transition: background-color 140ms cubic-bezier(.33,0,.2,1);

        &.small {
            width: 25vw;
            height: 30vh;

            .Link-name {
                font-size: 14px;
                text-transform: none;
            }
        }

        &:hover {
            background-color: black;
        }

        &-name {
            position: absolute;
            bottom: 40px;
            left: 40px;
            color: white;
            font-weight: bold;
            z-index: 10;
            text-transform: uppercase;
            font-family: 'Abel', sans-serif;

            &.centered {
                top: 65%;
                right: 0;
                left: 0;
                margin: auto;
                letter-spacing: 2px;
                color: #444;
            }
        }

        &-image {
            width: 100%;
            height: 100%;
            opacity: 0.9;
            object-fit: cover;
            transition: opacity .25s ease-in-out;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .MenuLinks .Link {
        width: 100%;
        height: 50vh;

        &.small {
            width: 100%;
        }
    }
}